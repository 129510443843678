import styled from '@emotion/styled'
import { Item, Props as ItemProps } from 'app/components/ContentSlider/Item'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  offers: ItemProps[]
}

export const OffersList = memo(function OffersList({ offers }: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      {offers.map((item, index) => (
        <Item key={index} variant="offers" {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  gap: 3.75rem;
  margin-top: -7.5rem;
  padding: 0 11.389vw;

  > a {
    width: calc(50% - 1.875rem);
  }

  @media (max-width: 1199px) {
    padding: 0 5vw;
  }

  @media (max-width: 1023px) {
    gap: 1.875rem;
    padding: 0 1.875rem;

    > a {
      width: calc(50% - 0.9375rem);
    }
  }

  @media (max-width: 767px) {
    gap: 0;
    margin-top: -3.75rem;
    padding: 0 1.25rem;

    > a {
      width: 100%;
      margin-bottom: 1.25rem;
    }
  }
`
